import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import has from "lodash.has"

import CarouselBg from "../components/CarouselBg"

export default function ContactPage({ data }) {
  return (
    <Layout>
      <div>
        <CarouselBg>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-right in-pic-height">
                <div className="in-text-holder">Reviews</div>
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  {has(data, "in_pic.sharp.fluid") && (
                    <Image
                      style={{ height: "100%" }}
                      fluid={data.in_pic.sharp.fluid}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </CarouselBg>
        <div className="breadcrums">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Link to="/">Home</Link> - Reviews
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-holder">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 text-center">
              <Image
                className="review-logo"
                fixed={data.review_logo_2.sharp.fixed}
              />
            </div>
            <div className="col-sm-8">
              <div className="review-holder">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magnadolore. Lorem ipsum dolor sit amet, consectetuer
                  adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                  laoreet dolore magnadolore.Lorem ipsum dolor sit amet,
                  consectetuer adipiscing elit, sed diam nonummy nibh euismod
                  tincidunt ut laoreet dolore magnadolore. Lorem ipsum dolor sit
                  amet, consectetuer adipiscing elit, sed diam nonummy nibh
                  euismod tincidunt ut laoreet dolore magnadolore.
                </p>
                <div className="corner" />
              </div>
              <div className="review-name">
                John Johnson
                <br />
                <span>Seo Executive / Robin Corporation</span>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-center">
              <Image
                className="review-logo"
                fixed={data.review_logo_1.sharp.fixed}
              />
            </div>
            <div className="col-sm-8">
              <div className="review-holder">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magnadolore. Lorem ipsum dolor sit amet, consectetuer
                  adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                  laoreet dolore magnadolore.Lorem ipsum dolor sit amet,
                  consectetuer adipiscing elit, sed diam nonummy nibh euismod
                  tincidunt ut laoreet dolore magnadolore. Lorem ipsum dolor sit
                  amet, consectetuer adipiscing elit, sed diam nonummy nibh
                  euismod tincidunt ut laoreet dolore magnadolore.
                </p>
                <div className="corner" />
              </div>
              <div className="review-name">
                John Johnson
                <br />
                <span>Seo Executive / Robin Corporation</span>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-center">
              <Image
                className="review-logo"
                fixed={data.review_logo_2.sharp.fixed}
              />
            </div>
            <div className="col-sm-8">
              <div className="review-holder">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magnadolore. Lorem ipsum dolor sit amet, consectetuer
                  adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                  laoreet dolore magnadolore.Lorem ipsum dolor sit amet,
                  consectetuer adipiscing elit, sed diam nonummy nibh euismod
                  tincidunt ut laoreet dolore magnadolore. Lorem ipsum dolor sit
                  amet, consectetuer adipiscing elit, sed diam nonummy nibh
                  euismod tincidunt ut laoreet dolore magnadolore.
                </p>
                <div className="corner" />
              </div>
              <div className="review-name">
                John Johnson
                <br />
                <span>Seo Executive / Robin Corporation</span>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 text-center">
              <Image
                className="review-logo"
                fixed={data.review_logo_1.sharp.fixed}
              />
            </div>
            <div className="col-sm-8">
              <div className="review-holder">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore
                  magnadolore. Lorem ipsum dolor sit amet, consectetuer
                  adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                  laoreet dolore magnadolore.Lorem ipsum dolor sit amet,
                  consectetuer adipiscing elit, sed diam nonummy nibh euismod
                  tincidunt ut laoreet dolore magnadolore. Lorem ipsum dolor sit
                  amet, consectetuer adipiscing elit, sed diam nonummy nibh
                  euismod tincidunt ut laoreet dolore magnadolore.
                </p>
                <div className="corner" />
              </div>
              <div className="review-name">
                John Johnson
                <br />
                <span>Seo Executive / Robin Corporation</span>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <a href="#" className="allport-2">
                More
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    in_pic: file(publicURL: { regex: "/in_pic_4.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    review_logo_1: file(publicURL: { regex: "/review_logo_1.jpg/" }) {
      sharp: childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    review_logo_2: file(publicURL: { regex: "/review_logo_2.jpg/" }) {
      sharp: childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
